var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($) {
  Drupal.behaviors.mppSortV2 = {
    attached: false,
    nodes: {},
    selectors: {
      html: 'html',
      mppContainer: '.js-mpp-container-v3, .js-mpp-custom-container-v2',
      sortContainer: '.js-product-grid-sortable',
      sortSelect: '.js-mpp-sort-menu',
      sortDropdown: '.js-mpp-sort-menu-selectBox-dropdown-menu',
      controlsContainer: '.js-mpp-container__controls',
      gnavHeader: '.js-site-header-formatter__header',
      stickyNodeContent: '.js-sticky-node-content',
    },
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      var $mpp = $(self.selectors.mppContainer, context);
      self.setup($mpp);
    },
    setup: function ($mpp) {
      var self = this;

      self.getDom($mpp);
      self.init($mpp);
      self.setEvents($mpp);
    },
    getDom: function ($mpp) {
      var self = this;

      $mpp.nodes = {};
      $mpp.nodes.$sortContainer = $(self.selectors.sortContainer, $mpp);
      $mpp.nodes.$sortSelect = $(self.selectors.sortSelect, $mpp);
      $mpp.nodes.$controlsContainer = $(self.selectors.controlsContainer, $mpp);
    },
    init: function ($mpp) {
      // initialize the selectBox for Sort custom dropdown
      $mpp.nodes.$sortSelect.selectBox({ mobile: true, loopOptions: true });
    },
    setEvents: function ($mpp) {
      var self = this;

      $mpp.nodes.$sortSelect.on('close', function () {
        $(self.selectors.html).removeClass('mpp-container__overlay');
        $(document).trigger('product:quickshopV2_destroy');
        // Sort By option reset the sort to default order, touts can be visible in this state
        // They will still be hidden if filters are active on grid
        if (!$('option:selected', this).attr('data-show-touts')) {
          $mpp.nodes.$sortContainer.addClass('js-product-grid--sorted');
        } else {
          $mpp.nodes.$sortContainer.removeClass('js-product-grid--sorted');
        }
        $mpp.nodes.$sortContainer.mixItUp('sort', this.value);

        self.scrollToMPPTop($mpp);
      });

      $mpp.nodes.$sortSelect.on('open', function () {
        $mpp.nodes.$controlsContainer[0].scrollIntoView();
        $(self.selectors.html).addClass('mpp-container__overlay');
        $(self.selectors.sortDropdown).css({ top: $mpp.offset().top });
      });
    },
    scrollToMPPTop: function ($mpp) {
      var self = this;
      var stickyNavHeight = 0;
      var mppTop = $mpp.offset().top;
      var curTop = $(window).scrollTop();

      if (mppTop < curTop) {
        stickyNavHeight += $(self.selectors.gnavHeader).outerHeight(true);
      }

      if ($(self.selectors.stickyNodeContent).length > 0) {
        stickyNavHeight += $mpp.nodes.$controlsContainer.outerHeight(true);
      }

      $(self.selectors.html).animate({
        scrollTop: mppTop - stickyNavHeight
      }, 500, "linear");
    },
  };
})(jQuery);
